"use strict";
(() => {
    function setTheme(mode = 'auto') {
        var _a;
        const userMode = localStorage.getItem('bs-theme');
        const sysMode = window.matchMedia('(prefers-color-scheme: light)').matches;
        const useSystem = mode === 'system' || (!userMode && mode === 'auto');
        const modeChosen = selectMode(mode);
        if (useSystem) {
            localStorage.removeItem('bs-theme');
        }
        else {
            localStorage.setItem('bs-theme', modeChosen);
        }
        document.documentElement.setAttribute('data-bs-theme', useSystem ? (sysMode ? 'light' : 'dark') : modeChosen);
        document.querySelectorAll('.mode-switch .btn').forEach(e => e.classList.remove('text-body'));
        (_a = document.getElementById(modeChosen)) === null || _a === void 0 ? void 0 : _a.classList.add('text-body');
    }
    function selectMode(mode) {
        const userMode = localStorage.getItem('bs-theme');
        const useSystem = mode === 'system' || (!userMode && mode === 'auto');
        if (useSystem) {
            return 'system';
        }
        if (mode === 'dark' || mode === 'light') {
            return mode;
        }
        return userMode;
    }
    document.addEventListener('DOMContentLoaded', () => {
        setTheme();
        document.querySelectorAll('.mode-switch .btn').forEach(e => e.addEventListener('click', () => setTheme(e.id)));
        window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', () => setTheme());
    });
})();
